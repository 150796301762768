.contact-page {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 180px;
}

.contact {
    display: flex;
    flex-direction: column;
}

.contact-container {
    background-color: #D7FBFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;

}

.contact-side {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.contact-column {
    display: flex;
    flex-direction: column;
    font-size: 60px;
}

.contact-side-header{
    display: flex;
    flex-direction: row;
    color: #1AB3C5;
    font-size: 60px;
}

input {
    box-sizing: border-box;
    padding-top: 12px;
    height: auto;
    width: 90%;
    padding: 8px 12px;
    resize: none;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 10px;
}

textarea {
    box-sizing: border-box;
    height: 150px;
    width: 90%;
    padding: 12px 12px;
    border: 1px solid #ccc;
    resize: none;
    border-radius: 8px;
    background-color: #f8f8f8;
}

@media ( max-width: 800px) {
    .contact-page {
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap-reverse;
    }
    
    .contact {
        display: flex;
        flex-direction: column;
    }
    
    .contact-container {
        background-color: #D7FBFF;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        font-size: 20px;
        margin-bottom: 10px;
    
    }
    
    .contact-side {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    textarea{
        width: 100%;
    }
    input {
        width: 100%;
    }

}