.about-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 80px;
    margin-bottom: 100px;
}

.about-text-company {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 30px;
    color: coral;
}

.about-card {
    display: flex;
    flex-direction: column;
    background-color: #D7FBFF;
    border-radius: 20px;
    width: 212px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    margin-left: 66px;
    margin-right: 30px;
}

.about-img {
    padding: 10px 10px;
    border-radius: 20px;
}

.about-card-header {
    font-family: Roboto;
    font-weight: 600;
    font-size: 18px;
    color: black;
    flex-wrap: nowrap;
    padding: 0 10px;
}

.about-card-description {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    color: black;
    padding: 0 10px;
}

.about-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin: 30px 0px;
    font-family: Roboto;
    color: #1AB3C5;
    font-size: 50px;
    font-weight: 700;
}

.about-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    color: black;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin: 0px 240px;
}

.about-ik {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.about-ik-container {
    width: 1000px;
    height: 400px;
    display: flex;
    background: #D7FBFF;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 20px;
}

.about-ik-img {
    opacity: 1;
}

.about-ik-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 70px 70px;
    font-family: Roboto;
    font-size: 22px;
    line-height: 116%;
    font-weight: 400;
}

.button-ik {
    background-color: #1AB3C5;
    width: 146px;
    border-radius: 8px;
    border: none;
    height: 40px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    font-family: Roboto;
}

@media (max-width: 1080px) {
    .about-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0px 5px;
    }

    .about-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: start;
        font-family: Roboto;
        color: #1AB3C5;
        font-size: 30px;
        margin: 14px 14px;
        font-weight: 600;
    }

    .about-text {
        display: flex;
        flex-direction: row;
        text-align: start;
        flex-wrap: wrap;
        color: black;
        text-align: start;
        font-family: Roboto;
        font-size: 16px;
        margin: 0px 30px;
    }

    .about-ik {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 20px 20px;
    }

    .about-ik-container {
        width: 280px;
        height: 212px;
        display: flex;
        background: #D7FBFF;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        border-radius: 20px;
    }

    .about-ik-img {
        width: 0px;
    }

    .about-ik-text {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        padding: 20px 20px;
    }
}
