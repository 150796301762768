.active {
    color: #1AB3C5;
}

nav {
    height: 100px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    box-shadow: 0px 0px 24px #1AB3C5;
    backdrop-filter: blur(10px);
    top: 0px;
}

nav a {
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin-right: 150px;
    margin-top: 40px;
    margin-bottom: 40px;
}

nav .sale {
    font-weight: 500;
    width: 146px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    border-radius: 8px;
    background-color: #1AB3C5;
}

nav .title {
    margin-left: 160px;
    margin-right: 350px;
}

nav ul {
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    display: flex;
}

nav ul Link {
    padding-right: 60px;
}

nav ul li {
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    list-style: none;
}

nav ul li a {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    display: block;
    text-decoration: none;
    color: black;
    padding: 0.5rem;
    margin-right: 30px;
    border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
    color: #1AB3C5;
}

nav .menu {
    display: none;
    position: absolute;
    margin-top: 18px;
    margin-right: 18px;
    top: 0.75rem;
    right: 0.75rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    height: 0.4rem;
    width: 100%;
    border-radius: 0.2rem;
    background-color: #1AB3C5;
}



@media (max-width: 1200px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        opacity: 1;
        background-color: white;
        width: 100%;
    }

    nav ul.open {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 1.5rem;

    }

    nav .title {
        margin-left: 40px;
        margin-top: 12px;
    }
    
    .sale {
        margin-left: 160px;
    }
}