.homepage {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 114px;
}

.header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 200px;
}

.img {
    margin-top: 30px;
    width: 620px;
    height: 620px;
}

.title {
    color: #1AB3C5;
    font-size: 50px;
    width: 500px;
    font-weight: 700;
    font-family: Roboto;
    line-height: 116%;
}

.sale-button {
    color: white;
    text-decoration: none;
    font-family: Roboto;
}

.text {
    line-height: 116%;
    font-size: 24px;
    width: 500px;
    margin-top: 26px;
    margin-bottom: 26px;
    font-weight: 400;
    font-family: Roboto;
}

.button {
    background-color: #1AB3C5;
    width: 248px;
    border-radius: 8px;
    border: none;
    height: 40px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    font-family: Roboto;
}

.vista-header {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: #1AB3C5;
}

.vista {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 100px;
}


.vista-title {
    color: #1AB3C5;
    font-size: 40px;
    font-family: Roboto;
    font-weight: 700;
    padding-bottom: 20px;
}


.vista-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    width: 600px;
    height: 208px;
    background-color: #D7FBFF;
    border-radius: 30px;
    margin-bottom: 20px;
}

.vista-text {
    color: black;
    width: 256px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 100%;
    font-size: 16px;
    padding: 34px;
}

@media (max-width: 600px) {
    .vista-container {
        display: flex;
        justify-content: space-between;
        width: 340px;
        height: 220px;
        background-color: #D7FBFF;
        border-radius: 30px;
        margin-bottom: 20px;
    }

    .vista-text {
        color: black;
        width: 150px;
        display: flex;
        flex-direction: row;
        font-weight: 300;
        font-family: Roboto;
        line-height: 100%;
        font-size: 16px;
        padding: 16px;
    }

    .image {
        height: 200px;
        padding: 10px 10px;
        width: 140px;
    }
}

@media (max-width: 1200px) {
    .text {
        font-size: 24px;
        width: 350px;
        margin-top: 26px;
        margin-bottom: 26px;
        font-weight: 400;
        font-family: Roboto;
    }

    .title {
        color: #1AB3C5;
        font-size: 40px;
        width: 350px;
        font-weight: 700;
        font-family: Roboto;
    }

    .homepage {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap-reverse;
    }

    .header {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 30px;
    }

    .img {
        margin-top: 20px;
        width: 350px;
        height: 350px;
    }
}