.footer-row {
    background-color: #D7FBFF;
    font-family: Roboto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 296px;
    flex-wrap: wrap;
}

.dev {
    height: 46px;
    background-color: #1AB3C5;
    font-family: Inter;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    justify-content: space-evenly;
    align-items: center;
}

.column {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer {
    margin-top: 100px;
    font-family: Roboto;
    background-color: #D7FBFF;
    color: black;
}

.mail-row {
    display: flex;
    flex-direction: row;
    color: black;
    text-decoration: none;
}

.mail-row:hover {
    text-decoration: underline;
}

.icon {
    color: black;
    padding-right: 10px;
    size: 32px;
}

@media (max-width: 720px) {
    .footer-row {
        background-color: #D7FBFF;
        font-family: Roboto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 420px;
        flex-wrap: wrap;
    }

    .mail-row {
        display: flex;
        flex-direction: row;
        color: black;
        text-decoration: none;
        margin-bottom: 10px
    }

    .dev {
        height: 46px;
        background-color: #1AB3C5;
        font-family: Inter;
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        justify-content: space-evenly;
        align-items: center;
    }

    .column {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-bottom: 20px;
    }

}