.services {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
}

.services h1 {
    font-family: Roboto;
    color: #1AB3C5;
    font-size: 50px;
    font-weight: 700;
    line-height: 116%;
}

.container {
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left: 96px;
    padding-right: 96px;
}

.card {
    display: flex;
    font-family: Roboto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 256px;
    height: 408px;
    border-radius: 20px;
    background-color: #D7FBFF;
    padding-bottom: 20px;
    margin-bottom: 10px;
}

.card-header {
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    color: #1AB3C5;
}

.description {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: black;
}

.card p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.card .button {
    width: 216px;
}

